import React, { useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { openMenuToggle } = useContext(ThemeContext);
  const EthioLogo = require("../../../images/png.png");
  const ELogo = require("../../../images/favicon.png");

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {toggle ? (
          <img
            className="w-75 text-center"
            alt="modern admin logo"
            src={ELogo}
          />
        ) : (
          <img
            className="w-75  text-center"
            alt="modern admin logo"
            src={EthioLogo}
          />
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
