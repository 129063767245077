/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { axiosInstance } from "../../../../services/AxiosInstance";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Translation = () => {
  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },

    // {
    //   Header: "Word",
    //   Footer: "Word",
    //   accessor: "title",
    //   Filter: ColumnFilter,
    // },

    {
      Header: "English Translation",
      Footer: "English Translation",
      accessor: "en",
      Filter: ColumnFilter,
    },
    {
      Header: "Spanish Translation",
      Footer: "Spasish Translation",
      accessor: "es",
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",

      Cell: ({ row }) => {
        const OnDelete = (id) => {
          setLoader(true);
          axiosInstance
            .delete(`subadmin/translations/${id}`)
            .then(function (response) {
              setLoader(false);
              Gettranslations();
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const [getTranslate, setgetTranslate] = useState({});
        const [engtranslate, setEngTranlate] = useState("");
        const [spatranslate, setSpaTranlate] = useState("");
        const ShowEdit = (id) => {
          setLoader(true);
          axiosInstance
            .get(`subadmin/translations/${id}`)
            .then(function (response) {
              if (response.data.status === true) {
                setLoader(false);
                console.log(response.data);
                onShowEdit();
                setgetTranslate(response.data.data);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const OnEdit = (id) => {
          setLoader(true);
          var urlencoded = new URLSearchParams();
          urlencoded.append(
            "title",
            engtranslate
              ? engtranslate.toLowerCase().replace(/ /g, "_")
              : getTranslate.en.toLowerCase().replace(/ /g, "_")
          );
          urlencoded.append(
            "en",
            engtranslate ? engtranslate : getTranslate.en
          );
          urlencoded.append(
            "es",
            spatranslate ? spatranslate : getTranslate.es
          );
          axiosInstance
            .put(`subadmin/translations/${id}`, urlencoded)
            .then(function (response) {
              if (response.data.status === true) {
                onHideEdit();
                Gettranslations();
                setEngTranlate("");
                setSpaTranlate("");
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,

                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        const [editdata, setEditData] = useState(false);
        const onShowEdit = () => setEditData(true);
        const onHideEdit = () => setEditData(false);
        return (
          <>
            <div className="d-flex">
              <button
                onClick={() => OnDelete(row.original.id)}
                className={`btn btn-outline-danger`}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => ShowEdit(row.original.id)}
                className={`btn btn-outline-success`}
              >
                <i className="fa fa-edit" />
              </button>
            </div>
            <Modal show={editdata} onHide={() => setEditData(false)} size="xl">
              <ModalHeader>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <h4 className="mt-3">{"Transaltion"}</h4>
                    </div>
                  </div>
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="container">
                  <div>
                    <div className="row">
                      <label className="form-label">
                        Words For Translation
                      </label>

                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">
                            English Translation
                          </label>
                          <textarea
                            ref={textAreaRef}
                            style={{ minHeight: "100px", resize: "vertical" }}
                            className="form-control"
                            type="text"
                            onChange={(e) => setEngTranlate(e.target.value)}
                            defaultValue={getTranslate.en}
                            placeholder="English Translation"
                          />
                        </div>
                        <div className="form-group mb-3 col-md-12">
                          <label className="form-label">
                            Spainsh Translation
                          </label>
                          <textarea
                            ref={textAreaRef}
                            defaultValue={getTranslate.es}
                            style={{ minHeight: "100px", resize: "vertical" }}
                            className="form-control"
                            onChange={(e) => setSpaTranlate(e.target.value)}
                            type="text"
                            placeholder="Spainsh Translation"
                          />
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="btn btn-outline-danger" onClick={onHideEdit}>
                  Close
                </div>

                <div
                  className="btn btn-outline-success"
                  onClick={() => OnEdit(row.original.id)}
                >
                  Sumbit
                </div>
              </ModalFooter>
            </Modal>
          </>
        );
      },
      Filter: ColumnFilter,
    },
  ]; //   const data = useMemo(() => MOCK_DATA, []);
  const [columns] = useState(COLUMNS);
  const textAreaRef = useRef(null);
  const textAreaRef2 = useRef(null);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const [fields, setFields] = useState([{ id: 1, word: "", en: "", es: "" }]);

  const addField = () => {
    const newField = {
      id: fields.length + 1,
      word: "",
      en: "",
      es: "",
    };
    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };

  const handleChange = (id, field, value) => {
    const updatedFields = fields.map((f) => {
      if (f.id === id) {
        return { ...f, [field]: value };
      }
      return f;
    });
    setFields(updatedFields);
  };
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height to auto to calculate the new height
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Set the height to the measured scroll height
    }
  }, [fields.en]);
  useEffect(() => {
    if (textAreaRef2.current) {
      textAreaRef2.current.style.height = "auto"; // Reset height to auto to calculate the new height
      textAreaRef2.current.style.height = `${textAreaRef2.current.scrollHeight}px`; // Set the height to the measured scroll height
    }
  }, [fields.es]);
  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const [showData, setShowData] = useState(false);
  const onHideData = () => setShowData(false);
  const onShowData = () => setShowData(true);
  const token = useSelector((state) => state.auth.auth.idToken);
  const Gettranslations = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}subadmin/translations`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  const Addtranslations = () => {
    setLoader(true);
    const formdata = new FormData();
    fields.map((e, i) =>
      formdata.append(
        `translate[${i}][title]`,
        e.en.toLowerCase().replace(/ /g, "_")
      )
    );
    fields.map((e, i) => formdata.append(`translate[${i}][en]`, e.en));
    fields.map((e, i) => formdata.append(`translate[${i}][es]`, e.es));
    axiosInstance
      .post("subadmin/translations", formdata)
      .then(function (response) {
        console.log(response, "business");
        if (response?.data?.status === true) {
          Gettranslations();
          onHideData();
          setLoader(false);
          setFields([{ id: 1, word: "", en: "", es: "" }]);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    Gettranslations();
  }, []);
  console.log(fields.map((e, i) => e.word.toLowerCase().replace(/" "/g, "_")));
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Translation</h4>
            <button
              className="btn btn-outline-primary float-right"
              onClick={onShowData}
            >
              Add Word <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showData} onHide={() => setShowData(false)} size="xl">
          <ModalHeader>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="mt-3">{"Transaltion"}</h4>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="container">
              <div>
                <div className="row">
                  <label className="form-label">Words For Translation</label>

                  {fields.map((field, i) => (
                    <div className="row" key={field.id}>
                      <div className="form-group mb-3 col-md-12 d-none">
                        <label className="form-label">Word</label>
                        <textarea
                          rows={50}
                          cols={50}
                          value={field.word}
                          className="form-control"
                          onChange={(e) =>
                            handleChange(field.id, "word", e.target.value)
                          }
                          placeholder="text"
                          style={{ height: "" }}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-12">
                        <label className="form-label">
                          English Translation
                        </label>
                        <textarea
                          ref={textAreaRef}
                          style={{ minHeight: "100px", resize: "vertical" }}
                          className="form-control"
                          type="text"
                          value={field.en}
                          onChange={(e) =>
                            handleChange(field.id, "en", e.target.value)
                          }
                          placeholder="English Translation"
                        />
                      </div>
                      <div className="form-group mb-3 col-md-12">
                        <label className="form-label">
                          Spainsh Translation
                        </label>
                        <textarea
                          ref={textAreaRef}
                          style={{ minHeight: "100px", resize: "vertical" }}
                          className="form-control"
                          type="text"
                          value={field.es}
                          onChange={(e) =>
                            handleChange(field.id, "es", e.target.value)
                          }
                          placeholder="Spainsh Translation"
                        />
                      </div>
                      <div className="col-md-12">
                        {fields.length === 1 ? null : (
                          <button
                            className="btn float-end btn-outline-danger mt-4"
                            onClick={() => removeField(field.id)}
                          >
                            <i className="fa fa-trash" />{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12">
                    <button
                      onClick={addField}
                      className="btn float-end btn-outline-primary mt-4"
                    >
                      <i className="fa fa-plus" />{" "}
                    </button>
                  </div>
                  {/* <div className="float-end">
              <div
                className="btn btn-primary float-end mb-3"
                onClick={TimeSlots}
              >
                Add All Time Slots
              </div>
            </div> */}
                </div>
              </div>{" "}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="btn btn-outline-danger" onClick={onHideData}>
              Close
            </div>

            <div className="btn btn-outline-success" onClick={Addtranslations}>
              Sumbit
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default Translation;
