/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useSelector } from "react-redux";
import Baseurl from "../../../../Baseurl";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { useTranslation } from "react-i18next";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  const { t }= useTranslation();
  return (
    <div>
      {t('search')} :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Blog = () => {
  const token = useSelector((state) => state.auth.auth);
  console.log(typeof(token?.idToken),'okin---->>>>>>>>>>')
  const { t }= useTranslation()

  const COLUMNS = [
    {
      Header: "Id",
      Footer: "Id",
      accessor: "id",
      Filter: ColumnFilter,
      //disableFilters: true,
    },

    {
      Header: t("title"),
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: t("type"),
      Footer: "Type",
      accessor: "type",
      Filter: ColumnFilter,
    },
    {
      Header: t("post"),
      Footer: "post",
      accessor: "post",
      Filter: ColumnFilter,
    },
    {
      Header: t("image"),
      Footer: "Image",
      accessor: "image",
      Cell: ({ row }) => {
        return (
          <>
            <a href={row.original.image} target="_blank" rel="noreferrer">
              {row.original.type === "video" ? (
                <video
                  src={row.original.image}
                  loop
                  autoPlay
                  className="rounded "
                  width={55}
                  height={50}
                />
              ) : (
                <img
                  src={row.original.image}
                  className="rounded "
                  width={55}
                  height={50}
                />
              )}
            </a>
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: t("status"),
      Footer: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        const changeStatus = (id, set) => {
          setLoader(true);
          axiosInstance
            .post(`subadmin/b/post/${set}/${id}`)
            .then(function (response) {
              if (response?.data?.status === true) {
                setLoader(false);
                toast.success(response.data.response);
                GetBlogs();
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              toast.error(error?.response?.data?.error);
            });
        };
        return (
          <>
            {row.original.status === 0 ? (
              <button
                className={"btn btn-outline-danger"}
                onClick={() => changeStatus(row.original.id, "activate")}
              >
                {"Deactive"}
              </button>
            ) : (
              <button
                className={"btn btn-outline-success"}
                onClick={() => changeStatus(row.original.id, "deactive")}
              >
                {"Active"}
              </button>
            )}
          </>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: t("action"),
      Footer: "Action",

      Cell: ({ row }) => {
        const onDelete = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}subadmin/posts/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token?.idToken}`,
            },
          };

          axios(config)
            .then(function (response) {
              console.log(response, "response");
              if (response?.status === 200) {
                setLoader(false);
                // GetCarehomes();

                Swal.fire({
                  showCloseButton: true,
                  toast: true,
                  icon: "success",
                  title: "Deleted SuccessFully",
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
                GetBlogs();
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };

        return (
          <>
            <div className="d-flex">
              <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-danger`}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => onEdit(row.original)}
                className={`btn btn-outline-success btn-sm `}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-pen" />
              </button>
            </div>
          </>
        );
      },
    },
  ]; //   const data = useMemo(() => MOCK_DATA, []);
  const [columns] = useState(COLUMNS);
  const [data, setData] = useState([]);
  const [business, setbusiness] = useState([]);
  const [post, setPost] = useState("");
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [showData, setShowData] = useState(false);
  const [blogID, setBlogID] = useState("");
  const [showEditData, setShowEditData] = useState(false);
  const [img, setImg] = useState("");
  const [loader, setLoader] = useState(false);

  const onShowData = () => {
    setShowEditData(false)
    setApi(initialState);
    setShowData(true);
  };
  const onHideData = () => {
    setApi(initialState);
    setPost('')
    setShowData(false);
  };

  const GetBlogs = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}subadmin/posts`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token?.idToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting blog");
        if (response?.data?.status === true) {
          setData(response?.data?.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const GetBusiness = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}subadmin/business`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token?.idToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Business");
        if (response?.data?.status === true) {
          setbusiness(response?.data?.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const onEdit = (values) => {
    setShowData(true)
    setShowEditData(true)
    setBlogID(values.id);
    const initialState1 = Api_Data?.reduce((acc, { name, value }) => {
      console.log(name,value, "0000>>>>>>>>>>>>");
      if (name === "post") {
        console.log(values[name])
        setPost(values[name]);
      } else {
        acc[name] = values[name] ? values[name] : "";
      }
      return acc;
    }, {});
    setApi(initialState1);
    console.log(post)
  };

  useEffect(() => {
    GetBlogs();
    GetBusiness();
  }, []);

  const Api_Data = [
    { name: "business_id", state: "business_id" },
    { name: "type", state: "type" },
    { name: "title", state: "title" },
    { name: "post", state: "post" },
  ];
  const initialState = Api_Data.reduce((acc, { name, state }) => {
    acc[state] = ""; // Set initial state value as empty string, you can change it as needed
    return acc;
  }, {});

  const [api, setApi] = useState(initialState);
  const AddData = () => {
    setLoader(true);
    const fromdata = new FormData();
    Api_Data?.map((e) =>
      fromdata.append(e.state, e.state === "post" ? post : api[e.state])
    );
    fromdata.append("image", img[0]);
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}subadmin/posts`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token?.idToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response.data.status === true) {
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: response?.data?.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setPost("");
          setApi(initialState);
          setImg("");
          GetBlogs();
          setShowData(false);
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: response?.data?.error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setApi((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };
      console.log(updatedState); // Console log the updated state
      return updatedState;
    });
  };
  const handleEdit = () => {
    setLoader(true);
    const fromdata = new URLSearchParams();
    Api_Data?.map((e) => fromdata.append(e.state, e?.state === 'post' ? post : api[e.state]) );
    {img && fromdata.append("image", img[0])}
    var config = {
      method: "put",
      url: `${Baseurl.baseurl}subadmin/posts/${blogID}`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token?.idToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.status === 200) {
          setLoader(false);
          GetBlogs();
          setApi(initialState);
          setPost('')
          setShowEditData(false)

          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: "Edit SuccessFully",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setShowData(false)
          setShowEditData(false);
          setBlogID('')
          setPost('')
          setApi(initialState)

        } else {
          setShowData(false)
          setShowEditData(false);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };
  console.log('img',img)
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div> 
          <div className="card-header">
            <h4 className="card-title float-left">{t('blogs')}</h4>
            <button className="btn btn-primary ms-2" onClick={onShowData}>
              {t('add_blog')} <i className="fa fa-plus" />
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups?.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup?.headers?.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {t('previous')}
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  {t('next')}
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{showEditData ?  'Edit Blog' : 'Add Blog'}</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {Api_Data?.map((e) => {
                const type = ["photo", "article", "video"];
                return (
                  <>
                    {e.state === "type" || e.state === "business_id" ? (
                      <>
                        <div className="col-md-12 mb-3">
                          <label htmlFor={e.state} className="text-capitalize">
                            {e.name.replace(/_/g, " ")}
                          </label>{" "}
                          <select
                            key={e.name}
                            name={e.state}
                            value={api[e.state]}
                            className="form-control text-capitalize"
                            onChange={handleInputChange}
                          >
                            <option selected hidden>
                              {e?.state === "type"
                                ? "Select Your Blog type..."
                                : "Select Your Business ..."}
                            </option>
                            {e?.state === "type"
                              ? type.map((e) => <option value={e}>{e}</option>)
                              : business?.map((e) => (
                                  <option value={e?.id}>{e?.title}</option>
                                ))}
                          </select>
                        </div>
                      </>
                    ) : e.state === "post" ? (
                      <ReactQuill
                        theme="snow"
                        value={post}
                        onChange={setPost}
                      />
                    ) : (
                      <div className="col-md-12 mb-3 ">
                        <label htmlFor={e.state} className="text-capitalize">
                          {e.name.replace(/_/g, " ")}
                        </label>{" "}
                        <input
                          key={e.name}
                          type="text"
                          name={e.state}
                          value={api[e.state]}
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    )}
                  </>
                );
              })}
              <div className="col-md-12  mb-3 mt-3">
                <input
                  type="file"
                  className="form-control "
                  onChange={(e) => setImg(e.target.files)}
                />
              </div>
              {/* <ReactQuill theme="snow" value={post} onChange={setPost} /> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            {t('close')}
          </div>
            {showEditData ?  <div className="btn btn-outline-success" onClick={handleEdit}>
            {t('update')}
          </div> : 
          <div className="btn btn-outline-success" onClick={AddData}>
            {t('sumbit')}
          </div>}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Blog;
