import React from "react";

/// Scroll

/// Components

const ChatBox = ({ onClick, toggle }) => {
  return (
    <div className={`chatbox ${toggle === "chatbox" ? "active" : ""}`}>
      <div className="chatbox-close" onClick={() => onClick()}></div>
      <div className="custom-tab-1">
        {/* <ul className="nav nav-tabs">
               {dataToggle.map((data, i) => (
                  <li className="nav-item" key={i}>
                     <Link
                        className={`nav-link ${
                           toggleTab === data.name.toLocaleLowerCase()
                              ? "active"
                              : ""
                        }`}
                        to="#"
                        data-toggle="tab"
                        href={data.href}
                        onClick={() =>
                           settoggleTab(data.name.toLocaleLowerCase())
                        }
                     >
                        {data.name}
                     </Link>
                  </li>
               ))}
            </ul> */}
      </div>
    </div>
  );
};

export default ChatBox;
