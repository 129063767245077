/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import axios from "axios";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Baseurl from "../../../../Baseurl";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Packages = () => {
  const keys = [
    "id",
    "type",
    "title",
    "description",
    "coupon_discount",
    "coupon",
    "duration",
    "duration_type",
    "price",
    "professional_type",
  ];

  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key,
      accessor: key,
      Filter: ColumnFilter,
    })),
    {
      Header: "Action",
      Footer: "Action",

      Cell: ({ row }) => {
        const onDelete = (id) => {
          setLoader(true);
          var config = {
            method: "delete",
            url: `${Baseurl.baseurl}subadmin/plans/${id}`,
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          };

          axios(config)
            .then(function (response) {
              if (response?.status === 200) {
                setLoader(false);
                GetPackages();

                Swal.fire({
                  showCloseButton: true,
                  toast: true,
                  icon: "success",
                  title: "Deleted SuccessFully",
                  animation: true,
                  position: "top-right",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });
              } else {
                setLoader(false);
              }
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };

        return (
          <>
            <div className="d-flex">
              <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-danger`}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-trash" />
              </button>
              <button
                onClick={() => {onEdit(row.original)}}
                className={`btn btn-outline-success btn-sm `}
                style={{ borderRadius: "50%" }}
              >
                <i className="fa fa-pen" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);
  const [feature, setFeature] = useState(false);
  const token = useSelector((state) => state.auth.auth.idToken);

  const [showData, setShowData] = useState(false);
  const [showEditData, setShowEditData] = useState(false);
  const [packageID, setPackageID] = useState("");
  const Api_Data = [
    { name: "type", state: "type" },
    { name: "title", state: "title" },
    { name: "description", state: "description" },
    { name: "coupon_discount", state: "coupon_discount" },
    { name: "coupon", state: "coupon" },
    { name: "duration", state: "duration" },
    { name: "price", state: "price" },
    { name: "professional_type", state: "professional_type" },
    { name: "duration_type", state: "duration_type" },
  ];
  const initialState = Api_Data.reduce((acc, { name, state }) => {
    acc[state] = ""; // Set initial state value as empty string, you can change it as needed
    return acc;
  }, {});

  const [api, setApi] = useState(initialState);
console.log(showData,'state========================>>>>')
  const onShowData = () => {
    setApi(initialState)
    setShowData(true)
  };
  const onHideData = () => {
    setApi(initialState)
    setShowEditData(false)
    setShowData(false)
  };

  const onEdit = (values) => {
     setShowData(true)
     setShowEditData(true)
    setPackageID(values.id)
    const initialState1 = Api_Data?.reduce((acc, { name, value }) => {
      acc[name] = values[name] ?  values[name] : "";
      return acc;
    }, {});
    setApi(initialState1);
  };

  const GetPackages = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}subadmin/plans`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  useEffect(() => {
    GetPackages();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setApi((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };
      console.log(updatedState); // Console log the updated state
      return updatedState;
    });
  };

  const AddData = () => {
    // setLoader(true);
    const fromdata = new FormData();
    fromdata.append("plan_type", feature ? "Feature" : "Signup");
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    var config = {
      method: "post",
      url: `${Baseurl.baseurl}subadmin/plans`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response.data.status === true) {
          setLoader(false);
          Swal.fire({
            icon: "success",
            title: response?.data?.response,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          GetPackages();
          setShowData(false);
        } else {
          setLoader(false);
          Swal.fire({
            icon: "error",
            title: response?.data?.error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const handleEdit = () => {
    setLoader(true);
    const fromdata = new URLSearchParams();
    fromdata.append("plan_type", feature ? "Feature" : "Signup");
    Api_Data?.map((e) => fromdata.append(e.state, api[e.state]));
    var config = {
      method: "put",
      url: `${Baseurl.baseurl}admin/plans/${packageID}`,
      data: fromdata,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response, "response");
        if (response?.status === 200) {
          setLoader(false);
          GetPackages();
          setApi(initialState);

          Swal.fire({
            showCloseButton: true,
            toast: true,
            icon: "success",
            title: "Edit SuccessFully",
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          setShowData(false)
          setShowEditData(false);
          setPackageID('')
          setApi(initialState)
        } else {
          setShowData(false)
          setShowEditData(false);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,
          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const professional_type = [
    "RN",
    "CNA",
    "HHA",
    "Amade Ilave",
    "Nutricionista",
    "Trabajadora Social",
  ];

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Packages</h4>

            <button className="btn btn-primary ms-2" onClick={onShowData}>
              Add Packages <i className="fa fa-plus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">Add Packages</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {Api_Data?.map((e) => {
                const type = ["Professional", "Business", "User", "Carehome"];
                const duration = ["day", "month", "week"];

                return (
                  <>
                    {e.state === "type" ? (
                      <>
                        <div className="col-md-6 mb-3">
                          <label htmlFor={e.state} className="text-capitalize">
                            {e.name.replace(/_/g, " ")}
                          </label>{" "}
                          <select
                            key={e.name}
                            type={
                              e.state === "price"
                                ? "number"
                                : e.state === "coupon_discount"
                                ? "tel"
                                : e.state === "duration"
                                ? "number"
                                : "text"
                            }
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          >
                            <option selected hidden>
                              Select Your type...
                            </option>
                            {type.map((e) => (
                              <option value={e}>{e}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : e.state === "professional_type" ? null : (
                      <>
                        {e.state === "duration_type" ? (
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor={e.state}
                              className="text-capitalize"
                            >
                              {e.name.replace(/_/g, " ")}
                            </label>{" "}
                            <select
                              key={e.name}
                              name={e.state}
                              value={api[e.state]}
                              className="form-control"
                              onChange={handleInputChange}
                            >
                              <option selected hidden>
                                Select your duration type...
                              </option>
                              {duration.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor={e.state}
                              className="text-capitalize"
                            >
                              {e.name.replace(/_/g, " ")}
                            </label>{" "}
                            <input
                              key={e.name}
                              type={
                                e.state === "price"
                                  ? "number"
                                  : e.state === "coupon_discount"
                                  ? "tel"
                                  : "text"
                              }
                              name={e.state}
                              value={api[e.state]}
                              className="form-control"
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              })}
              {api["type"] === "Professional" ? (
                <>
                  <div className="col-md-6 md-3">
                    <label className="text-capitalize">Professional type</label>{" "}
                    <select
                      className="form-control"
                      value={api["professional_type"]}
                       name={"professional_type"}
                      onChange={handleInputChange}
                    >
                      <option selected hidden>
                        Select Your Professional type...
                      </option>
                      {professional_type.map((e) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}

              {api["type"] === "Carehome" || api["type"] === "Professional" ? (
                <>
                  <div className="col-md-6 md-3 mt-5">
                    <input
                      type="checkbox"
                      name="feature"
                      onChange={(e) => {
                        setFeature(e.target.checked);
                      }}
                    />{" "}
                    <label className="text-capitalize">For Feature</label>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            Close
          </div>

          {showEditData ? <div className="btn btn-outline-success" onClick={handleEdit}>
           Edit
          </div> : 
           <div className="btn btn-outline-success" onClick={AddData}>
           Sumbit
         </div>}
        </ModalFooter>
      </Modal>

      {/* <Modal
        show={showEditData}
        onHide={() => setShowEditData(false)}
        size="md"
      >
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">Edit Packages</h4>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              {Api_Data?.map((e) => {
                const type = ["Professional", "Business", "User", "Carehome"];
                const duration = ["day", "month", "week"];
                return (
                  <>
                    {e.state === "type" ? (
                      <>
                        <div className="col-md-6 mb-3">
                          <label htmlFor={e.state} className="text-capitalize">
                            {e.name.replace(/_/g, " ")}
                          </label>{" "}
                          <select
                            key={e.name}
                            type={
                              e.state === "price"
                                ? "number"
                                : e.state === "coupon_discount"
                                ? "tel"
                                : e.state === "duration"
                                ? "number"
                                : "text"
                            }
                            name={e.state}
                            value={api[e.state]}
                            className="form-control "
                            onChange={handleInputChange}
                          >
                            <option selected hidden>
                              Select Your type...
                            </option>
                            {type.map((e) => (
                              <option value={e}>{e}</option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : e.state === "professional_type" ? null : (
                      <>
                        {e.state === "duration_type" ? (
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor={e.state}
                              className="text-capitalize"
                            >
                              {e.name.replace(/_/g, " ")}
                            </label>{" "}
                            <select
                              key={e.name}
                              name={e.state}
                              value={api[e.state]}
                              className="form-control"
                              onChange={handleInputChange}
                            >
                              <option selected hidden>
                                Select your duration type...
                              </option>
                              {duration.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        ) : (
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor={e.state}
                              className="text-capitalize"
                            >
                              {e.name.replace(/_/g, " ")}
                            </label>{" "}
                            <input
                              key={e.name}
                              type={
                                e.state === "price"
                                  ? "number"
                                  : e.state === "coupon_discount"
                                  ? "tel"
                                  : "text"
                              }
                              name={e.state}
                              value={api[e.state]}
                              className="form-control"
                              onChange={handleInputChange}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              })}
              {api["type"] === "Professional" ? (
                <>
                  <div className="col-md-6 md-3">
                    <label className="text-capitalize">Professional type</label>{" "}
                    <select
                      className="form-control "
                      name={"professional_type"}
                      value={api["professional_type"]}
                      onChange={handleInputChange}
                    >
                      <option selected hidden>
                        Select Your Professional type...
                      </option>
                      {professional_type.map((e) => (
                        <option value={e}>{e}</option>
                      ))}
                    </select>
                  </div>
                </>
              ) : null}

              {api["type"] === "Carehome" || api["type"] === "Professional" ? (
                <>
                  <div className="col-md-6 md-3 mt-5">
                    <input
                      type="checkbox"
                      name="feature"
                      onChange={(e) => {
                        setFeature(e.target.checked);
                      }}
                    />{" "}
                    <label className="text-capitalize">For Feature</label>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div
            className="btn btn-outline-danger"
            onClick={() => {
              setShowEditData(false);
            }}
          >
            Close
          </div>

          <div className="btn btn-outline-success" onClick={handleEdit}>
            Sumbit
          </div>
        </ModalFooter>
      </Modal> */}
    </>
  );
};
export default Packages;
