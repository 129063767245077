/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";

//import './table.css';
import "./filtering.css";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  const { t }= useTranslation();
  return (
    <div>
      {t('search')} :{" "}
      <input
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Care_Homes_building = () => {
  const keys = ["id", "title", "carehome.director"];
  const { t }= useTranslation();
  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " ").replace(/\./g, " "),
      Footer: key,
      accessor: key,
      Filter: ColumnFilter,
    })),

    {
      Header: t("view_floor"),
      Footer: "View Floor",
      Cell: ({ row }) => {
        return (
          <>
            <div className="d-flex ">
              <Link to={`/floor/${row.original.id}`}>
                <button className={`btn btn-outline-light btn-lg`}>
                  <i className="fa fa-eye" />
                </button>
              </Link>
            </div>
          </>
        );
      },
    },
    {
      Header: t("action"),
      Footer: "Action",
      Cell: ({ row }) => {
        const onDelete = (id) => {
          setLoader(true);
          axiosInstance
            .delete(`/subadmin/delete_building/${id}`)
            .then(function (response) {
              console.log(response, "response");
              GetCarehomes();
              toast.success("Record Has been deleted!!");
              setLoader(false);
            })
            .catch(function (error) {
              setLoader(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              Swal.fire({
                showCloseButton: true,
                toast: true,
                icon: "error",
                title: error?.response?.data?.message,
                animation: true,
                position: "top-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
            });
        };
        return (
          <>
            <div className="d-flex ">
              {/* <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-plus" />
              </button> */}
              <button
                onClick={() => onDelete(row.original.id)}
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const [data, setData] = useState([]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [loader, setLoader] = useState(false);

  const [showData, setShowData] = useState(false);
  const [carehome, setCareHome] = useState("");
  const [payment_method, setPayment_method] = useState("");
  const onShowData = () => setShowData(true);
  const onHideData = () => setShowData(false);
  const { id } = useParams();
  const GetCarehomes = () => {
    setLoader(true);
    axiosInstance
      .get(`subadmin/buildings?carehome_id=${id}`)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        Swal.fire({
          showCloseButton: true,

          toast: true,
          icon: "error",
          title: error?.response?.data?.message,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      });
  };

  const addName = () => {
    const formdata = new FormData();
    formdata.append("carehome_id", id);
    formdata.append("title", carehome);
    formdata.append("type", payment_method);
    axiosInstance
      .post("subadmin/buildings", formdata)
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setLoader(false);
          GetCarehomes();
          setShowData(false);
          toast.success(response.data.message);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => {
    GetCarehomes();
  }, []);
  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">{t('care_homes')}</h4>

            <button className="btn btn-primary ms-2" onClick={onShowData}>
              {t('new_project')} <i className="fa fa-plus" />
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {t('previous')}
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {t('next')}
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showData} onHide={() => setShowData(false)} size="md">
        <ModalHeader>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h4 className="mt-3">{t('add_building')}</h4>
              </div>
              <div className="col-md-6">
                <div className="float-right"></div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="">{t('building_name')}</label>

                <input
                  type="text"
                  placeholder="Enter CareHome Name"
                  className="form-control mb-3"
                  onChange={(e) => setCareHome(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="">{t('payment_method')}</label>

                <input
                  type="text"
                  placeholder="Enter payment Method"
                  className="form-control mb-3"
                  onChange={(e) => setPayment_method(e.target.value)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn btn-outline-danger" onClick={onHideData}>
            {t('close')}
          </div>

          <div className="btn btn-outline-success" onClick={addName}>
            {t('sumbit')}
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Care_Homes_building;
